<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>代理商名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="代理商名称/编号"
          ></el-input>
        </div>
        <div class="item">
          <i class="jianju">审核状态</i>
          <el-select
            v-model="query.checkStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="待审核" value="WAIT"></el-option>
            <el-option label="已通过" value="PASS"></el-option>
            <el-option label="已驳回" value="REJECT"></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>激活状态</i>
          <el-select
            v-model="query.activateStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未激活" value="NOTACTIVATE"></el-option>
            <el-option label="已激活" value="ACTIVATE"></el-option>
            <el-option label="已冻结" value="FROZEN"></el-option>
            <el-option label="已关闭" value="CLOSED"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="getLists"
            >查询</el-button
          >
          <el-button
            v-auth="'ACCOUNT:AGENT:AGENTS/ADD'"
            class="addBt"
            @click="addclickHander"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>代理商名称</p>
          </td>
          <td>
            <p>代理商编号</p>
          </td>
          <td>
            <p>商户数量</p>
          </td>
          <td>
            <p>审核状态</p>
          </td>
          <td>
            <p>激活状态</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.createTime }}</p>
          </td>
          <td>
            <p>{{ v.agentName }}</p>
          </td>
          <td>
            <p>{{ v.agentId }}</p>
          </td>
          <td>
            <p>{{ v.merchantNum }}</p>
          </td>
          <td>
            <p>{{ checkStatus[v.checkStatus] }}</p>
          </td>
          <td>
            <p>{{ activateStatus[v.activateStatus] }}</p>
          </td>
          <td>
            <p>
              <a
                v-auth="'ACCOUNT:AGENT:AGENTS/DETAIL'"
                href="javascript:;"
                @click="detailTab(v.agentId)"
                class="lianjie"
                >详情</a
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { agentsLists } from "@/api/user/agentsManager.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        startTime: "",
        endTime: "",
        checkStatus: "",
        pageNo: 1,
        pageSize: 10,
        activateStatus: "",
        keyword: "",
      },
      totalNum: 0,
      tabData: [],
      checkStatus: {
        WAIT: "待审核",
        PASS: "已通过",
        REJECT: "已驳回",
      },
      activateStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
    };
  },
  computed: {
    ...mapState({
      agentsQueryDetail: "user_agentsQuery",
    }),
  },
  created() {
    if (this.agentsQueryDetail) {
      this.query = this.agentsQueryDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },
  methods: {
    ...mapMutations({
      setAgentsQuery: "user_setAgentsQuery",
      setAgents: "user_setAgents",
    }),
    //获取列表
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      agentsLists(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.agents;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //新增按钮
    addclickHander() {
      this.setAgents(null);
      this.$router.push("/user/add_agentlist_xx");
    },
    detailTab(id) {
      this.$router.replace({
        name: "agentlist_xq",
        query: {
          id,
        },
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setAgentsQuery(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{width: 48.284%;}
.con_from .lineP .item i {
  width: 74px;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 74px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 74px - 24.67px - 10px) / 2);
}
.jiaoyi_box p b {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(72, 184, 182, 1);
}

.jiaoyi_box p i {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

.jiaoyi_box p {
  padding-top: 18px;
  width: 100%;
  text-align: center;
}

.jiaoyi {
  width: 100%;
  height: 90px;
  margin-top: 20px;
}

.jiaoyi_box {
  float: left;
  width: 485px;
  height: 90px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.addBt {
  float: right;
  width: 90px;
  height: 36px;
  border-color: #d7dae2;
  background-color: #fff;
  margin-left: 20px;
  padding: 0;
  color: #606266;
}

.addBt:hover {
  background-color: #fff;
  color: #48b8b6;
}

.searchBt {
  float: right;
  background: #48b8b6;
  border-color: #48b8b6;
  width: 90px;
  height: 36px;
  padding: 0;
  color: #ffffff;
}

</style>
